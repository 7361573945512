body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: cornflowerblue;
  color: white;
  padding-top: 100px;
  padding-bottom: 100px;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fr {
  display: flex;
}

.fc {
  display: flex;
  flex-direction: column;
}

.jc {
  justify-content: center;
}

.ac {
  align-items: center;
}

div > a {
  color: white;
  text-decoration: none;
}

.MuiTableCell-head {
  font-weight: bold !important;
  color: #555 !important;
}

.MuiTableCell-body {
  color: #555 !important;
}

@media (max-width: 1000px) {
    .hide_when_small {
      display: none !important;
    }
}

@media (max-width: 540px) {
  .hide_when_really_small {
    display: none !important;
  }
}

@media (max-width: 540px) {
  .column_when_small {
    flex-direction: column;
  }
}

.recharts-surface {
  overflow: visible;
}